const plantType_GardenSunflower = {name: "Garden Sunflower", icon: "GS", color: "#FFFF00", growthTime: 1, originDate: "", category: "flower"}
const plantType_LemonBasil = {name: "Lemon Basil", icon: "LB", color: "#90EE90", growthTime: 2, originDate: "", category: "herb"}
const plantType_BeefsteakTomato = {name: "Beefsteak Tomato", icon: "BT", color: "#FF6347", growthTime: 2, originDate: "", category: "vegetable"}
const plantType_YellowSquash = {name: "Yellow Squash", icon: "SQ", color: "#FFEB2A", growthTime: 3, originDate: "", category: "vegetable"}
const plantType_GardenCosmos = {name: "Garden Cosmos", icon: "GC", color: "#D8BFD8", growthTime: 1, originDate: "", category: "flower"}
const plantType_GardenPansy = {name: "Garden Pansy", icon: "GP", color: "#0000FF", growthTime: 1, originDate: "", category: "flower"}

const Plants = [plantType_GardenSunflower, plantType_LemonBasil, plantType_BeefsteakTomato,
plantType_YellowSquash, plantType_GardenCosmos, plantType_GardenPansy]

export default Plants