import logo from './logo.svg';
import { useState, useEffect } from 'react';
import './App.css';
import Plants from './gameData.js'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Board />
      </header>
    </div>
  );
}

function MyButton() {
  const [count, setCount] = useState(0);

  function handleClick() {
    setCount(count + 1);
  }

  return (
    <button onClick={handleClick}>
      Clicked {count} times
    </button>
  );
}

function Square({value, onSquareClick}) {
  let output = ""
  let plantColor = "#C4A484"
  if (value){
    output = value.icon
    plantColor = value.color
  }
  return (
    <button className="square" style={{background: plantColor}} onClick={onSquareClick}>
      {output}
    </button>
  );
}

function Seed({seedVal, onSeedClick}) {
  return (
    <button className="seed" style={{background: seedVal.color}} onClick={onSeedClick}>
      {seedVal.icon}
    </button>
  )
}

function Store({ShelfArray}) {

}

function Board() {
  const [squares, setSquares] = useState(Array(81).fill(null));
  const [chosenSeed, setChosenSeed] = useState(null);
  const [energyTokens, setEnergyTokens] = useState(0);
  const [chosenObject, setChosenObject] = useState({name: "", icon: "", color: "", growthTime: 0, originDate: "", category: ""})
  const utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');

  useEffect(() => {
    const existingSquareArray = localStorage.getItem("SquaresArray");
    const lastLoginDay = localStorage.getItem("LastLoginDay");
    const historicalEnergyTokens = localStorage.getItem("HistoricalEnergyTokens");
    if (existingSquareArray) {
      const foundArray = JSON.parse(existingSquareArray);
      setSquares(foundArray);
      dailyGrowthEngine(foundArray);
    }
    if (lastLoginDay){
      if (lastLoginDay == utc){
        //Logging in on same day
        setEnergyTokens(parseInt(historicalEnergyTokens))
      }
      else{
        //Logging in on new day
        localStorage.setItem("LastLoginDay", utc);
        localStorage.setItem("HistoricalEnergyTokens", parseInt(historicalEnergyTokens) + 6);
        setEnergyTokens(parseInt(historicalEnergyTokens) + 6)
      }
    }
    else{
      //First time logging in
      localStorage.setItem("LastLoginDay", utc);
      localStorage.setItem("HistoricalEnergyTokens", 6);
      setEnergyTokens(6)
    }
    
  }, []);

  function handleClick(i) {
    if (squares[i] || !chosenSeed || !spendEnergy(1)) {
      if (squares[i]){
        setChosenObject(squares[i]);
        document.getElementById("statsContainer").style.visibility = "visible";
      }
      return;
    }
    const nextSquares = squares.slice();
    nextSquares[i] = chosenSeed;
    nextSquares[i].originDate = utc;
    setSquares(nextSquares);
    setChosenObject(nextSquares[i]);
    localStorage.setItem('SquaresArray',  JSON.stringify(nextSquares));
  }

  function spendEnergy(amount) {
    if (energyTokens - amount >= 0) {
      setEnergyTokens(energyTokens - amount);
      localStorage.setItem("HistoricalEnergyTokens", energyTokens - amount);
      return true;
    }
    else {
      return false;
    }
  }

  function handleReset(){
    const nextSquares = Array(81).fill(null).slice();
    localStorage.setItem('SquaresArray',  JSON.stringify(nextSquares));
    setSquares(nextSquares);
  }

  function handleSeedClick(i){
    setChosenSeed(i);
    setChosenObject(i);
    document.getElementById("statsContainer").style.visibility = "visible";
  }

  function returnStoreShelf(day)
  {
    var randSeedNum = cyrb128(day);
    var randomNum = mulberry32(randSeedNum[0]);
    var output1 = Math.floor(randomNum() * 6)
    var output2 = Math.floor(randomNum() * 6)
    var output3 = Math.floor(randomNum() * 6)
    while (output1 == output2)
    {
      output2 = Math.floor(randomNum() * 6)
    }
    while (output2 == output3 || output1 == output3)
    {
      output3 = Math.floor(randomNum() * 6)
    }
    var seed1 = Plants[output1];
    var seed2 = Plants[output2];
    var seed3 = Plants[output3];
    return(
      
      <div>
        <div>
        Energy Tokens: {energyTokens}
        </div>
        <div className="store-row">
          <Seed seedVal={seed1} onSeedClick={() => handleSeedClick(seed1)} />
          <Seed seedVal={seed2} onSeedClick={() => handleSeedClick(seed2)} />
          <Seed seedVal={seed3} onSeedClick={() => handleSeedClick(seed3)} />
        </div>
      </div>
    )
  }

  function cyrb128(str) {
    let h1 = 1779033703, h2 = 3144134277,
        h3 = 1013904242, h4 = 2773480762;
    for (let i = 0, k; i < str.length; i++) {
        k = str.charCodeAt(i);
        h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
        h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
        h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
        h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
    }
    h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
    h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
    h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
    h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
    return [(h1^h2^h3^h4)>>>0, (h2^h1)>>>0, (h3^h1)>>>0, (h4^h1)>>>0];
}

  function mulberry32(a) {
    return function() {
      a |= 0; a = a + 0x6D2B79F5 | 0;
      var t = Math.imul(a ^ a >>> 15, 1 | a);
      t = t + Math.imul(t ^ t >>> 7, 61 | t) ^ t;
      return ((t ^ t >>> 14) >>> 0) / 4294967296;
    }
}

  function returnSquareObject(val){
    return(
      <Square value={squares[val]} onSquareClick={() => handleClick(val)} />
    );
  }

  function returnGardenRow(val){
    const gardenRow = []
    for (let i = 0; i < 9; i++) {
      gardenRow.push(returnSquareObject(i + (val * 9)))
    }
    return(
      <div className="board-row">
        {gardenRow}
      </div>
    )
  }

  const PlantStats = ({ PlantObject }) => {
    return (
      <div className="plant-stats">
        <img src={PlantObject.icon} alt={PlantObject.icon} className="plant-image" />
        <div className="plant-info">
          <h2>{PlantObject.name}</h2>
          <p>Days until mature: {PlantObject.growthTime}</p>
          <p>Planted: {PlantObject.originDate}</p>
        </div>
      </div>
    );
  };

const gardenPlot = [];
for (let i = 0; i < 9; i++) {
  gardenPlot.push(returnGardenRow(i))
}

  return (
    <>
      {returnStoreShelf(utc)}
      <br/>
      <p>
        <div className="board-container">
          <div className="garden-container">
            {gardenPlot}
          </div>
          <div className="plant-stats-container" id="statsContainer">
            <PlantStats
              PlantObject={chosenObject}
            />
          </div>
        </div>
      </p>


      <div>
        <button onClick={handleReset}>Reset Board</button>
      </div>
    </>
  );
}

function dailyGrowthEngine(squares) {
  var adjacentObjects = findAdjacentObjects(squares);
  localStorage.setItem('AdjacentObjects',  JSON.stringify(adjacentObjects));
}

function findAdjacentObjects(grid) {
  const adjacentObjects = [];

  const directions = [
    -10, // top
    -9, // top-right
    1, // right
    11, // bottom-right
    10, // bottom
    9, // bottom-left
    -1, // left
    -11, // top-left
  ];

  const size = Math.sqrt(grid.length);
  for (let i = 0; i < grid.length; i++) {
    const obj = grid[i];
    if (obj !== null) {
      const row = Math.floor(i / size);
      const col = i % size;
      const neighbors = [];
      for (const dir of directions) {
        const neighborIndex = i + dir;
        if (
          neighborIndex >= 0 &&
          neighborIndex < grid.length &&
          grid[neighborIndex] !== null
        ) {
          const neighborRow = Math.floor(neighborIndex / size);
          const neighborCol = neighborIndex % size;
          neighbors.push([neighborRow, neighborCol]);
        }
      }

      if (neighbors.length > 0) {
        adjacentObjects.push({ obj, index: [row, col], neighbors });
      }
    }
  }

  return adjacentObjects;
}


export default App;
